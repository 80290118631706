<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Review Bank Account</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-8 min-w-180">
  <!-- Current Bank Account Details -->
  <div class="text-2xl font-semibold tracking-tight text-left pb-2">
    Bank Account Details
  </div>
  <mat-divider class="pb-4 border-t-tilled-neutral"></mat-divider>
  <div class="px-2 grid w-full grid-cols-2">
    <div
      class="justify-self-start text-lg tracking-tight text-tilled-secondary"
    >
      Account Number
    </div>
    <div
      class="justify-self-end text-right text-lg tracking-tight text-tilled-primary pb-2"
    >
      {{ currentAccountNumber }}
    </div>
    <div
      class="justify-self-start text-lg tracking-tight text-tilled-secondary"
    >
      Routing Number
    </div>
    <div
      class="justify-self-end text-right text-lg tracking-tight text-tilled-primary pb-2"
    >
      {{ currentRoutingNumber }}
    </div>
  </div>

  <!-- Bank Account Document -->
  <div class="text-2xl font-semibold tracking-tight text-left pb-2 pt-4">
    Bank Account Document(s)
  </div>
  <mat-divider class="pb-2 border-t-tilled-neutral"></mat-divider>
  <div class="flex flex-row pl-2 max-w-180 overflow-x-auto">
    <ng-container *ngFor="let document of bankDocuments; let i = index">
      <div
        *ngIf="bankDocuments.length > 1"
        class="mt-2 text-lg text-blue-500 cursor-pointer break-keep"
        (click)="getFileData(document)"
      >
        {{ documentTypes[i] }}
      </div>
      <div
        *ngIf="bankDocuments.length === 1"
        class="mt-2 text-lg text-tilled-primary break-keep"
      >
        {{ documentTypes[i] }}
      </div>
      <div
        *ngIf="i !== bankDocuments.length - 1"
        class="mt-2 px-4 text-lg text-tilled-primary"
      >
        |
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="safeUrl"
    class="mt-2 w-full h-[90%] max-w-180 border-gray-500 border-0"
  >
    <iframe
      *ngIf="mimeType === 'application/pdf'"
      class="min-w-192 w-full h-full"
      [src]="safeUrl"
    ></iframe>
    <img
      *ngIf="mimeType !== 'application/pdf'"
      [src]="safeUrl"
      [alt]="
        'File type not supported. Try right clicking it and opening in a new tab'
      "
      class="w-full h-full border-gray-500 border-4"
    />
  </div>
  <div *ngIf="!safeUrl">
    <div
      *ngIf="failedToLoadFile"
      class="p-8 text-2xl font-semibold tracking-tight text-center"
    >
      The onboarding document(s) failed to load, try again.
    </div>
    <div
      *ngIf="noFilesUploaded"
      class="p-8 text-2xl font-semibold tracking-tight text-center"
    >
      There has been no onboarding documents uploaded.
    </div>
    <div
      *ngIf="!failedToLoadFile && !noFilesUploaded"
      class="p-8 text-2xl font-semibold tracking-tight text-center"
    >
      Loading Document(s)...
    </div>
  </div>

  <form class="flex flex-col" [formGroup]="editBankAccountForm">
    <fieldset
      class="flex flex-col"
      [disabled]="false"
    >
      <!-- Edit Bank Account Details -->
      <div class="text-2xl font-semibold tracking-tight text-left pb-2 pt-10">
        Edit Bank Account Details
      </div>
      <div
        class="text-lg tracking-tight text-tilled-secondary break-normal w-fit pb-6"
      >
        Skip this section if the bank account details match. Only edit if the
        numbers appear transposed.
      </div>
      <!-- Account Number -->
      <mat-form-field>
        <mat-label>Account Number</mat-label>
        <input matInput formControlName="accountNumber" maxLength="20" />
      </mat-form-field>
      <!-- Routing Number -->
      <mat-form-field>
        <mat-label>Routing Number</mat-label>
        <input matInput formControlName="routingNumber" maxLength="9" />
        <mat-error *ngIf="editBankAccountForm.controls['routingNumber'].invalid"
          >The routing number must be valid.
        </mat-error>
      </mat-form-field>

      <!-- Verify Bank Account -->
      <div class="text-2xl font-semibold tracking-tight text-left pb-2 pt-4">
        Verify Bank Account
      </div>
      <!-- Verify Bank Account Checkbox -->
      <div class="pl-1">
        <mat-checkbox
          class="pt-2"
          formControlName="verifyCheck"
          (change)="verifyCheckChanged($event)"
        >
          <div class="text-lg tracking-tight text-tilled-primary">
            Verify this Bank Account
          </div>
        </mat-checkbox>
        <div class="pt-2 text-md tracking-tight text-tilled-secondary">
          Account number and routing need to match the Bank Letter or Voided
          Check.
        </div>
        <mat-error
          class="pt-1 text-sm font-medium"
          *ngIf="
            !editBankAccountForm.get('verifyCheck').value &&
              editBankAccountForm.get('verifyCheck').touched;
            else noError
          "
          >You must verify the bank account to save and submit the
          application.</mat-error
        >
        <ng-template #noError>
          <div class="pt-1 text-sm font-medium">&nbsp;</div>
        </ng-template>
      </div>
    </fieldset>
  </form>

  <!-- Save/Verify Button -->
  <div class="flex w-full justify-end">
    <button
      mat-flat-button
      color="primary"
      (click)="verifyBankAccountClicked()"
      [disabled]="
        !bankAccountVerified || editBankAccountForm.invalid || currentlySaving
      "
    >
      <span>Save</span>
    </button>
  </div>
</div>
