<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">{{ filename }}</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div
  *ngIf="safeUrl"
  class="mt-4 w-full h-[90%] max-w-screen max-h-screen border-gray-500 border-0"
>
  <iframe
    *ngIf="mimeType === 'application/pdf'"
    class="min-w-192 w-full h-full"
    [src]="safeUrl"
  ></iframe>
  <img
    *ngIf="mimeType !== 'application/pdf'"
    [src]="safeUrl"
    [alt]="
      'File type not supported. Try right clicking it and opening in a new tab'
    "
    class="w-full h-full border-gray-500 border-4"
  />
</div>
<div
  *ngIf="textResponse"
  class="mt-4 w-full h-[90%] max-w-screen max-h-screen border-gray-500 border-0"
>
  <div class="w-full">
    <span>{{ textResponse }}</span>
  </div>
</div>
