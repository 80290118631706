import { AdminDocument } from '@admin-api/index';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reject-document-dialog',
  templateUrl: './reject-document-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RejectDocumentDialogComponent {
  public rejectDocForm: UntypedFormGroup;
  public rejectionReasons = Object.values(AdminDocument.RejectedReasonEnum);

  constructor(
    public matDialogRef: MatDialogRef<RejectDocumentDialogComponent>,
    private fb: FormBuilder,
  ) {
    this.rejectDocForm = this.fb.group({
      rejectionReason: [null, Validators.required],
    });
  }

  public cancelClicked() {
    this.matDialogRef.close(false);
  }

  public submitClicked() {
    if (this.rejectDocForm.invalid) {
      return;
    }
    this.matDialogRef.close(
      this.rejectDocForm.get('rejectionReason')
        .value as AdminDocument.RejectedReasonEnum,
    );
  }

  public mapRejectionReasonToString(
    reason: AdminDocument.RejectedReasonEnum,
  ): string {
    switch (reason) {
      case AdminDocument.RejectedReasonEnum.BLURRY:
        return 'Blurry document';
      case AdminDocument.RejectedReasonEnum.CORRUPT:
        return 'Corrupt document';
      case AdminDocument.RejectedReasonEnum.INVALID:
        return 'Invalid document';
      case AdminDocument.RejectedReasonEnum.DAMAGED:
        return 'Damaged document';
      case AdminDocument.RejectedReasonEnum.INCOMPLETE:
        return 'Incomplete document';
      case AdminDocument.RejectedReasonEnum.SCANNED:
        return 'Scanned document';
      case AdminDocument.RejectedReasonEnum.NOT_NEEDED:
        return 'Document not needed';
      default:
        return '';
    }
  }
}
