import { AdminExtendedDispute } from '@admin-api/index';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'dispute-status',
  templateUrl: './dispute-status.component.html',
  styleUrls: ['./dispute-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DisputeStatusComponent implements OnChanges {
  @Input() dispute: AdminExtendedDispute;

  public displayStatus: string;
  public colorClass: 'gray' | 'red' | 'yellow' | 'green' = 'gray';
  public icon: 'done' | 'undo' | 'close' | 'info' | 'access_time_filled' = null;
  public tooltipText: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'dispute') {
        this.computeDisplayStatus();
      }
    }
  }

  private computeDisplayStatus(): void {
    // always reset default values
    this.displayStatus = undefined;
    this.colorClass = 'gray';
    this.icon = null;
    this.tooltipText = undefined;

    if (this.dispute.status === AdminExtendedDispute.StatusEnum.WON) {
      this.displayStatus = 'Won';
      this.colorClass = 'green';
      this.icon = 'done';
      this.tooltipText = `Reversed at ${this.formatDate(
        this.dispute.reversed_at,
      )}`;
    } else if (
      this.dispute.status === AdminExtendedDispute.StatusEnum.NEEDS_RESPONSE
    ) {
      this.displayStatus = 'Needs Response';
      this.colorClass = 'gray';
      this.icon = 'info';
      this.tooltipText = `Closing at ${this.formatDate(
        this.dispute.closing_at,
      )}`;
    } else if (
      this.dispute.status ===
      AdminExtendedDispute.StatusEnum.WARNING_NEEDS_RESPONSE
    ) {
      this.displayStatus = 'Warning Needs Response';
      this.colorClass = 'gray';
      this.icon = 'info';
      this.tooltipText = `Closing at ${this.formatDate(
        this.dispute.closing_at,
      )}`;
    } else if (
      this.dispute.status === AdminExtendedDispute.StatusEnum.UNDER_REVIEW
    ) {
      this.displayStatus = 'Under Review';
      this.colorClass = 'yellow';
      this.icon = 'info';
    } else if (
      this.dispute.status ===
      AdminExtendedDispute.StatusEnum.WARNING_UNDER_REVIEW
    ) {
      this.displayStatus = 'Warning Under Review';
      this.colorClass = 'yellow';
      this.icon = 'info';
      this.tooltipText = `Closing at ${this.formatDate(
        this.dispute.closing_at,
      )}`;
    } else if (this.dispute.status === AdminExtendedDispute.StatusEnum.LOST) {
      this.displayStatus = 'Lost';
      this.colorClass = 'red';
      this.icon = 'close';
    } else if (this.dispute.status === AdminExtendedDispute.StatusEnum.CLOSED) {
      this.displayStatus = 'Closed';
      this.colorClass = 'red';
      this.icon = 'close';
      this.tooltipText = `Closed at ${this.formatDate(
        this.dispute.closing_at,
      )}`;
    } else if (
      this.dispute.status === AdminExtendedDispute.StatusEnum.WARNING_CLOSED
    ) {
      this.displayStatus = 'Warning Closed';
      this.colorClass = 'green';
      this.icon = 'done';
      this.tooltipText = `Closed at ${this.formatDate(
        this.dispute.closing_at,
      )}`;
    } else {
      this.displayStatus = this.dispute.status;
    }
  }

  private formatDate(d: Date | string): string {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dt = new Date(d);
    return dt.toLocaleString('en-US', {
      timeZone: localTimezone,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
      minute: '2-digit',
      hour: 'numeric',
      second: '2-digit',
    });
  }
}
