<div class="flex flex-col max-w-100 min-w-100 max-h-screen -m-6">
  <mat-toolbar matDialogTitle class="mat-primary m-0">
    <mat-toolbar-row class="flex">
      <span class="dialog-title flex-1 truncate">Submit Merchant Document</span>
      <button
        class="close-icon"
        mat-icon-button
        (click)="matDialogRef.close(false)"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- Add/Edit form -->
  <form
    class="flex flex-col flex-auto p-8 overflow-y-auto"
    [formGroup]="submitDocumentUploadForm"
  >
    <fieldset class="flex flex-col flex-auto" [disabled]="isLoading">
      <!-- Agreement Type -->
      <p class="font-bold">Document Requirement: {{ requirement | snakeCaseSplit | titlecase }}</p>
      <br>
      <div *ngIf="(type === 'file')">
        <div>
          <p class="font-bold">File to upload:</p>
          <p *ngIf="fileToUpload">{{ fileToUpload.name }}</p>
          <p *ngIf="!fileToUpload">No file selected.</p>
        </div>
        <input
          class="custom-file-input"
          type="file"
          (change)="onFileChange($event)"
          #file
        />
        <button
          mat-flat-button
          color="primary"
          (click)="file.click()"
          *ngIf="!isLoading"
        >
          {{ fileToUpload ? 'Select Another File' : 'Select File' }}
        </button>
        <button mat-flat-button color="primary" disabled="true" *ngIf="isLoading">
          Saving
        </button>
      </div>
      <div *ngIf="type === 'written'">
        <p class="font-bold">Written Response:</p>
        <mat-form-field class="min-w-full">
          <textarea matInput
          [rows]="5"
          [formControlName]="'writtenResponse'">
        </textarea>
        </mat-form-field>
      </div>
    </fieldset>

    <mat-divider class="mt-6"></mat-divider>

    <div class="flex mt-6">
      <!-- Send -->
      <button
        class="ml-auto"
        mat-flat-button
        [color]="'primary'"
        (click)="save()"
        [disabled]="submitDocumentUploadForm.invalid || isLoading"
      >
        Save
      </button>
    </div>
  </form>
</div>
