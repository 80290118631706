<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Reject Application</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-8 max-w-128">
  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    This will reject the merchant's application, and it will not be submitted
    the processor.
  </div>

  <div
    class="text-lg font-bold tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    Are you sure you want to continue?
  </div>

  <div class="flex w-full justify-end">
    <!-- Cancel button -->
    <button class="mr-4" mat-button (click)="cancelClicked()">Cancel</button>
    <!-- Request info button -->
    <button mat-flat-button color="primary" (click)="submitClicked()">
      <span>Reject Application</span>
    </button>
  </div>
</div>
