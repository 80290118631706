import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'user-list-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserListImpersonateComponent implements OnInit {
  impersonationForm: UntypedFormGroup;

  constructor(
    public matDialogRef: MatDialogRef<UserListImpersonateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUserListImpersonateComponentData,
    private _formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.impersonationForm = this._formBuilder.group({
      reason: ['', [Validators.required]],
      goto_whitelabel_domain: [!!this.data.white_label_domain],
    });
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  proceed(): void {
    if (this.impersonationForm.invalid) {
      return;
    }

    const gotoWhiteLabelDomain =
      this.impersonationForm.value['goto_whitelabel_domain'];

    this.matDialogRef.close({
      reason: this.impersonationForm.value['reason'],
      redirect_url_domain: gotoWhiteLabelDomain
        ? this.data.white_label_domain
        : null,
    } as IUserListImpersonateResponseData);
  }
}

export interface IUserListImpersonateComponentData {
  user_name?: string;
  user_email?: string;
  user_id?: string;
  white_label_domain?: string;
}

export interface IUserListImpersonateResponseData {
  reason?: string;
  redirect_url_domain?: string; // hawkeye or white label
}
