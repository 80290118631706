import { AdminExtendedUser, VerifyUserCreateParams } from '@admin-api/index';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
interface IVerifyUserCreateDialogData {
  user: AdminExtendedUser;
}
@Component({
  selector: 'verify-user-create-form-dialog',
  templateUrl: './verify-user-create-dialog.component.html',
  styleUrls: ['./verify-user-create-dialog.component.scss'],
})
export class VerifyUserCreateDialogComponent {
  @Output() recheck = new EventEmitter();
  recheckVerification(): void {
    this.matDialogRef.close({ reason: 'recheck' });
  }

  verifyCreateForm: UntypedFormGroup;
  dialogTitle: string = 'Send Verification Code for ';
  verifyUserCreateParams: VerifyUserCreateParams;
  user: AdminExtendedUser;
  show: boolean = true;

  constructor(
    public matDialogRef: MatDialogRef<VerifyUserCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IVerifyUserCreateDialogData,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.user = dialogData.user;

    this.dialogTitle = this.dialogTitle + this.user.name;

    this.verifyUserCreateParams = {
      channel: 'email', // default to email
      user_contact: this.user.email,
    };

    this.verifyCreateForm = this.verifyUserCreateForm();

    this.verifyCreateForm.get('channel').valueChanges.subscribe((channel) => {
      let userContact = '';
      if (channel === VerifyUserCreateParams.ChannelEnum.EMAIL) {
        userContact = this.dialogData.user.email;
      } else if (channel === VerifyUserCreateParams.ChannelEnum.SMS) {
        userContact = ''; //user phone number if we start collecting them
      } else {
        userContact = this.dialogData.user.id;
      }
      this.verifyCreateForm.patchValue({
        user_contact: userContact,
      });
    });
  }

  verifyUserCreateForm(): UntypedFormGroup {
    return this._formBuilder.group({
      channel: [this.verifyUserCreateParams.channel, Validators.required],
      user_contact: [
        this.verifyUserCreateParams.user_contact,
        Validators.required,
      ],
    });
  }
}
