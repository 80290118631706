import { AdminPayoutIntent } from '@admin-api/index';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PayoutIntentViewModel } from 'app/modules/accounts/payout-intents/payout-intents.component';

@Component({
  selector: 'payout-intent-status',
  templateUrl: './payout-intent-status.component.html',
  styleUrls: ['./payout-intent-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PayoutIntentStatusComponent implements OnChanges {
  @Input() payoutIntent: PayoutIntentViewModel;

  public displayStatus: string;
  public colorClass: 'gray' | 'red' | 'yellow' | 'green' = 'gray';
  public icon: 'done' | 'close' | 'info' = null;
  public tooltipText: string;

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'payoutIntent') {
        this.computeDisplayStatus();
      }
    }
  }

  private computeDisplayStatus(): void {
    // always reset default values
    this.displayStatus = undefined;
    this.colorClass = 'gray';
    this.tooltipText = undefined;

    if (this.payoutIntent.status === AdminPayoutIntent.StatusEnum.PENDING) {
      this.displayStatus = 'Pending';
      this.colorClass = 'gray';
      this.icon = 'info';
      this.tooltipText = `Next attempt after ${this.formatDate(
        this.payoutIntent.statusDate,
      )} (exact timing depends on Paysafe API window and minimum balance requirements)`;
    } else if (
      this.payoutIntent.status === AdminPayoutIntent.StatusEnum.PROCESSED
    ) {
      this.displayStatus = 'Processed';
      this.colorClass = 'green';
      this.icon = 'done';
      this.tooltipText = `Processed on
        ${this.formatDate(this.payoutIntent.statusDate)}`;
    } else if (
      this.payoutIntent.status === AdminPayoutIntent.StatusEnum.FAILED
    ) {
      this.displayStatus = 'Failed';
      this.colorClass = 'red';
      this.icon = 'close';
      this.tooltipText = `Last failure at ${this.formatDate(
        this.payoutIntent.statusDate,
      )}`;
    } else if (
      this.payoutIntent.status === AdminPayoutIntent.StatusEnum.READY
    ) {
      this.displayStatus = 'Processing';
      this.colorClass = 'yellow';
      this.tooltipText = `Now processing`;
    }
  }

  private formatDate(d: Date | string): string {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dt = new Date(d);
    return dt.toLocaleString('en-US', {
      timeZone: localTimezone,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
      minute: '2-digit',
      hour: 'numeric',
      second: '2-digit',
    });
  }
}
