import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reject-application-dialog',
  templateUrl: './reject-application-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RejectApplicationDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<RejectApplicationDialogComponent>,
  ) {}

  public cancelClicked() {
    this.matDialogRef.close(false);
  }

  public submitClicked() {
    this.matDialogRef.close(true);
  }
}
