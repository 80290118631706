<div
  id="document-status-chip"
  [class]="colorClass"
  class="text-center w-fit"
  [matTooltip]="tooltipText"
  matTooltipPosition="below"
  matTooltipClass="multiline-tooltip"
>
  {{ displayStatus }}
</div>
