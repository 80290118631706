import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-very-document-dialog',
  templateUrl: './verify-document-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class VerifyDocumentDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<VerifyDocumentDialogComponent>,
  ) {}

  public cancelClicked() {
    this.matDialogRef.close(false);
  }

  public submitClicked() {
    this.matDialogRef.close(true);
  }
}
