import { BankAccount, BankAccountVerificationHistory } from '@admin-api/index';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes bank account status and verification history data and outputs the verification method string
 */
@Pipe({
  name: 'bankVerificationMethod',
})
export class BankAccountVerificationMethodPipe implements PipeTransform {
  /**
   *
   * @param status Current status of a bank account
   * @param verificationHistory Verification history of a bank account
   * @returns Verification method in a string displayable form
   */
  transform(
    status: BankAccount.StatusEnum,
    verificationHistory: BankAccountVerificationHistory[],
  ): string {
    if (!verificationHistory || verificationHistory.length === 0) {
      return null;
    }

    for (const history of verificationHistory.reverse()) {
      if (history.verification_status === status) {
        return history.verification_method ===
          BankAccountVerificationHistory.VerificationMethodEnum.PLAID
          ? 'Plaid'
          : 'Tilled Manual';
      }
    }
    return null;
  }
}
