import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-submit-application-dialog',
  templateUrl: './submit-application-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SubmitApplicationDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<SubmitApplicationDialogComponent>,
  ) {}

  public cancelClicked() {
    this.matDialogRef.close(false);
  }

  public submitClicked() {
    this.matDialogRef.close(true);
  }
}
