<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Email documents to processor</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-4 max-w-128 max-h-160">
  <div
    class="text-lg tracking-tight text-tilled-primary break-normal w-full pb-8 text-center"
  >
    Send the documents to the processor for this merchant
  </div>
  <div
    class="text-lg font-bold tracking-tight text-tilled-primary break-normal w-full pb-2 text-left"
  >
    Select email recipient
  </div>
  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-4 text-left"
  >
    Choose where you want the documents to be emailed
  </div>
  <form [formGroup]="emailRecipientForm">
    <div class="flex flex-row flex-auto w-full">
      <div fxLayout="row" fxLayoutAlign="start start">
      <mat-radio-group formControlName="emailRecipientType" aria-label="Select an option">
        <mat-radio-button value="paysafePended" (change)="onItemChange($event)" class="pb-5">
          Paysafe - Pended merchant
          <br>
          platformonboarding@paysafe.com
        </mat-radio-button>
        <br>
        <mat-radio-button value="paysafeActive" (change)="onItemChange($event)" class="pb-5">
          Paysafe - Active merchant (Holds)
          <br>
          partnersupport@paysafe.com
          </mat-radio-button>
        <br>
        <mat-radio-button value="tsysPended" (change)="onItemChange($event)">
          TSYS - Pended merchant - Custom recipient
          <br>
          Enter the email of the responsible underwriter
          <br>
          <mat-form-field class="w-full">
            <input
              matInput
              formControlName="tsysPendedCustomRecipient"
              maxLength="255"
              placeholder="garfield@tsys.com"
            />
          </mat-form-field>
        </mat-radio-button>
        <br>
        <mat-radio-button value="tsysActive" (change)="onItemChange($event)">
          TSYS - Active merchant (Holds) - Custom recipient
          <br>
          Enter the email of the responsible underwriter
          <br>
          <mat-form-field class="w-full">
            <input
              matInput
              formControlName="tsysActiveCustomRecipient"
              maxLength="255"
              placeholder="garfield@tsys.com"
            />
          </mat-form-field>
        </mat-radio-button>
      </mat-radio-group>
      </div>
    </div>
  </form>
  <mat-divider class="w-full pb-4"></mat-divider>
  <div
  class="text-lg font-bold tracking-tight text-tilled-primary break-normal w-full pb-2 text-left"
  >
  Write a note to the recipient (optional)
  </div>
  <form [formGroup]="noteForm">
    <div class="w-full">
      <div fxLayout="row" fxLayoutAlign="start start">
    <mat-form-field class="min-w-full">
      <textarea matInput
      [rows]="5"
      [formControlName]="'recipientNote'">
    </textarea>
    </mat-form-field>
    </div></div>
  </form>
  <mat-divider class="w-full pb-4"></mat-divider>
  <div
  class="text-lg font-bold tracking-tight text-tilled-primary break-normal w-full pb-2 text-left"
  >
  Choose documents and written responses
  </div>
  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-4 text-left"
  >
    Select all the verified documents and written responses that you wish to include in the email
  </div>
  <form [formGroup]="documentForm">
    <div class="w-full">
      <div fxLayout="row" fxLayoutAlign="start start">
  <div formArrayName="documents" *ngFor="let document of verifiedDocuments; let i = index">
    <mat-checkbox class="pt-2" [formControlName]="i">
      <div class="text-lg tracking-tight text-tilled-primary">
        {{subtypeToTitle.has(document.subtype) ? subtypeToTitle.get(document.subtype) : 'Unknown Document Subtype'}}
      </div>
      <div class="text-md tracking-tight text-tilled-secondary">
        Submitted: {{getSubmittedTime(document)}},Verified: {{getSubmittedTime(document)}}
      </div>
      <div *ngIf="document.written_response" class="pt-2 text-md tracking-tight text-tilled-secondary">
        Merchant Response: "{{document.written_response}}"
      </div>
    </mat-checkbox>
  </div>
  <div *ngIf="noDocumentsSelected" style="color: red">
    At least one document must be selected
  </div>
  </div>
  </div>
  </form>
  <div class="flex w-full justify-end  pt-4 pb-4">
    <button mat-stroked-button class="mr-4" (click)="cancelClicked()">
      <mat-icon [svgIcon]="'mat_outline:cancel'"></mat-icon>
      <span class="ml-2 mr-1">Cancel</span>
    </button>
    <button mat-flat-button [color]="'primary'" class="mr-4" (click)="submitClicked()">
      <mat-icon [svgIcon]="'mat_outline:email'"></mat-icon>
      <span class="ml-2 mr-1">Email Documents</span>
    </button>
  </div>
</div>
