import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-open-application-dialog',
  templateUrl: './open-application-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OpenApplicationDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<OpenApplicationDialogComponent>,
  ) {}

  public cancelClicked() {
    this.matDialogRef.close(false);
  }

  public openAppClicked() {
    this.matDialogRef.close(true);
  }
}
