import {
  AdminExtendedAccount,
  AdminNote,
  AdminNotesCreateRequest,
  AdminService,
} from '@admin-api/index';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNoteDialogComponent implements OnInit, OnDestroy {
  data: any;
  account: AdminExtendedAccount;
  addNoteForm: UntypedFormGroup;
  sourceType: string;
  sourceId: string;
  isLoading: boolean = true;
  errorMessage: string = null;

  constructor(
    public matDialogRef: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _adminService: AdminService,
    private _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
  ) {
    this.data = _data;
    this.account = this.data['account'];
    this.sourceType = this.data['sourceType'];
    this.sourceId = this.data['sourceId'];
    this.addNoteForm = this.fb.group({
      newNote: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  ngOnDestroy(): void {}

  saveNote(): void {
    const adminNoteCreateRequest: AdminNotesCreateRequest = {
      account_id: this.account.id,
      source_type: this.sourceType as AdminNote.SourceTypeEnum,
      source_id: this.sourceId,
      note: this.addNoteForm.value.newNote,
    };
    this._adminService
      .adminCreateNote({ adminNotesCreateRequest: adminNoteCreateRequest })
      .subscribe(
        (res) => {
          this._snackBar.open('Note Added', null, {
            duration: 1000,
          });
          this.matDialogRef.close();
        },
        (error) => {
          this.isLoading = false;
          if (error?.error?.message) {
            this.errorMessage = 'Error: ' + error.error.message;
          } else if (error?.message) {
            this.errorMessage = 'Error: ' + error.message;
          } else {
            this.errorMessage = 'Unknown error';
          }
          this.showError(this.errorMessage);
        },
        () => {},
      );
  }

  private showError(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 5000,
      verticalPosition: 'top',
    });
  }
}
