<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="text-center title dialog-title">Add Note</span>
    <span class="spacer"></span>
    <button
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<form
class="flex flex-col flex-auto p-8 overflow-y-auto"
[formGroup]="addNoteForm"
>
<fieldset class="flex flex-col flex-auto" [disabled]="isLoading">
  <div>
    <p class="font-bold">Note</p>
    <mat-form-field class="min-w-full" cdkFocusInitial>
      <textarea matInput
      [rows]="5"
      maxLength="1023"
      [formControlName]="'newNote'">
    </textarea>
    </mat-form-field>
    <p style="color: red">*Do not enter sensitive or financial data in notes</p>
  </div>
</fieldset>

<mat-divider class="mt-6"></mat-divider>

<div class="flex mt-6">
  <!-- Send -->
  <button
    class="ml-auto"
    mat-flat-button
    [color]="'primary'"
    (click)="saveNote()"
    [disabled]="addNoteForm.invalid || isLoading"
  ><mat-icon
    class="mr-4 icon-size-5"
    [svgIcon]="'mat_outline:check'"
  ></mat-icon> Save Notes
  </button>
</div>
</form>
