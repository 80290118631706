<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-primary m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between end">
      <span class="dialog-title">{{ dialogTitle }}</span>
      <button
        class="close-icon"
        mat-icon-button
        (click)="matDialogRef.close()"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-10 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="verifyCreateForm">
      <div class="message-box info mb-7" *ngIf="show">
        <p class="mb-0">Select method of verification:</p>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-radio-group
          formControlName="channel"
          aria-label="Select an option"
        >
          <mat-radio-button value="email" class="pb-5"
            >Email ({{ user.email }})</mat-radio-button
          >
          <br />
          <mat-radio-button value="sms" class="pb-5" disabled
            >SMS</mat-radio-button
          >
          <br />
          <mat-radio-button value="service_code" class="pb-5"
            >Service code</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </form>
  </div>

  <div mat-dialog-actions class="p-0 m-0 pl-5" fxLayout="row">
    <button
      mat-raised-button
      color="primary"
      class="send-verification-code-button"
      (click)="matDialogRef.close(verifyCreateForm)"
      [disabled]="verifyCreateForm.disabled"
      aria-label="SEND"
    >
      Send Code
    </button>
    <button
      mat-raised-button
      color="primary"
      class="recheck-verification-code-button ml-5"
      (click)="recheckVerification()"
      [disabled]="verifyCreateForm.disabled"
      aria-label="RECHECK"
      *ngIf="user.verification_status === 'failed'"
    >
      Recheck Code
    </button>
  </div>
</div>
