import { ModelFile } from '@admin-api/index';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface IViewDocumentDialogData {
  file: ModelFile;
  data: any;
  filename?: string;
  textResponse?: string;
}

@Component({
  selector: 'app-view-document-dialog',
  templateUrl: './view-document-dialog.component.html',
  styleUrls: ['./view-document-dialog.component.scss'],
})
export class ViewDocumentDialogComponent {
  public file: ModelFile;
  public data: any;
  public filename: string;
  private objectUrl: string;
  public safeUrl: SafeResourceUrl;
  public mimeType: string;
  public textResponse: string;

  constructor(
    public matDialogRef: MatDialogRef<ViewDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IViewDocumentDialogData,
    private sanitizer: DomSanitizer,
  ) {
    this.matDialogRef.afterClosed().subscribe(() => {
      window.URL.revokeObjectURL(this.objectUrl);
    });

    this.textResponse = dialogData.textResponse;
    if (this.textResponse) {
      this.filename = 'Written Response';
      return;
    }

    this.file = dialogData.file;
    this.data = dialogData.data;
    this.filename = dialogData.filename;

    switch (this.file.type) {
      case ModelFile.TypeEnum.PDF:
        this.mimeType = 'application/pdf';
        break;
      case ModelFile.TypeEnum.JPG:
        this.mimeType = 'image/jpg';
        break;
      case ModelFile.TypeEnum.PNG:
        this.mimeType = 'image/png';
        break;
      case ModelFile.TypeEnum.TIFF:
        this.mimeType = 'image/tiff';
        break;
      default:
        return;
    }

    const object = new File([this.data], this.filename, {
      type: this.mimeType,
    });
    this.objectUrl = window.URL.createObjectURL(object);

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.objectUrl,
    );
  }
}
