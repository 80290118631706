import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * We want to add `tilled-client` headers when making API calls to Tilled
 */
@Injectable()
export class TilledClientHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.api)) {
      const clonedReq = req.clone({
        headers: req.headers
          .set('tilled-client-name', 'admin_console')
          .set('tilled-client-version', environment.version),
      });
      return next.handle(clonedReq);
    } else {
      return next.handle(req);
    }
  }
}
