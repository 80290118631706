import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MinusSignToParensPipe } from './minus-sign-to-parens.pipe';

/**
 * Takes minor units as input (and currency code; default to USD) and outputs human text
 */
@Pipe({
  name: 'minorUnitsToCurrency',
})
export class MinorUnitsToCurrencyPipe implements PipeTransform {
  /**
   *
   * @param value Minor units (integer); cents, for USD
   * @param currency 3 letter code, defaults to USD
   * @param minusOrParens 'minus' for minus sign, 'parens' to be wrapp in parens "-$5.00" vs "($5.00)"
   * @param decimalDigits Show 2 - 5 decimal digits. Default is 2.
   * @returns
   */
  transform(
    value: number,
    currency = 'USD',
    minusOrParens: 'minus' | 'parens' = 'minus',
    decimalDigits: 2 | 3 | 4 | 5 | 6 | 7 = 2,
  ): any {
    const currencyPipe = new CurrencyPipe('en-US', 'USD');

    let digitsInfo = '1.2';
    if (decimalDigits >= 3 && decimalDigits <= 7) {
      digitsInfo = '1.2-' + decimalDigits.toString();
    }

    let v = currencyPipe.transform(
      value / 100,
      currency?.toUpperCase(),
      'symbol',
      digitsInfo,
    );
    if (minusOrParens === 'parens') {
      const parensPipe = new MinusSignToParensPipe();
      v = parensPipe.transform(v);
    }
    return v;
  }
}
