import { ApiModule, Configuration } from '@admin-api/index';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import {
  OktaAuthModule,
  OktaAuthStateService,
  OKTA_CONFIG,
} from '@okta/okta-angular';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from '../environments/environment';

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules,
  //relativeLinkResolution: 'legacy'
};

import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { AuthService } from './core/auth/auth.service';
import { httpInterceptorProviders } from './core/interceptors';

const oktaConfig: OktaAuthOptions = {
  // Required
  issuer: environment.okta.issuer,
  clientId: environment.okta.client_id,
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  // Configure TokenManager to use sessionStorage instead of localStorage
  //   tokenManager: {
  //     storage: 'sessionStorage',
  //   },
};
const oktaAuth = new OktaAuth(oktaConfig);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OktaAuthModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),

    // Tilled API Client
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.api,
          accessToken: () => {
            return oktaAuth.getAccessToken();
          },
        }),
    ),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      // This doesn't seem right, but all Okta examples show this.
      // I would assume OKTA_AUTH would useValue: oktaAuth
      // and OKTA_CONFIG would useValue oktaConfig
      // but nothing worked unless I did it precisely like this.
      provide: OKTA_CONFIG,
      useValue: { oktaAuth },
    },
    httpInterceptorProviders,
  ],
})
export class AppModule {
  constructor(
    _authService: AuthService,
    private _authStateService: OktaAuthStateService,
  ) {
    _authService.handleAuthStateObservable(_authStateService.authState$);
  }
}
