import { AdminExtendedUser, VerifyUserCheckParams } from '@admin-api/index';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
interface IVerifyUserCheckDialogData {
  user: AdminExtendedUser;
}
@Component({
  selector: 'verify-user-check-form-dialog',
  templateUrl: './verify-user-check-dialog.component.html',
  styleUrls: ['./verify-user-check-dialog.component.scss'],
})
export class VerifyUserCheckDialogComponent {
  @Output() resend = new EventEmitter();
  resendVerification(): void {
    this.matDialogRef.close({
      data: this.verifyCheckForm.value,
      reason: 'resend',
    });
  }
  verifyCheckForm: UntypedFormGroup;
  dialogTitle: string = 'Check Verification Code of ';
  verifyUserCheckParams: VerifyUserCheckParams;
  user: AdminExtendedUser;
  show: boolean = true;

  constructor(
    public matDialogRef: MatDialogRef<VerifyUserCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IVerifyUserCheckDialogData,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.user = dialogData.user;

    this.dialogTitle = this.dialogTitle + this.user.name;

    this.verifyUserCheckParams = {
      verification_code: '',
      user_contact: this.user.email,
      channel: 'email', // default to email
    };

    this.verifyCheckForm = this.verifyUserCheckForm();

    this.verifyCheckForm.get('channel').valueChanges.subscribe((channel) => {
      let userContact = '';
      if (channel === VerifyUserCheckParams.ChannelEnum.EMAIL) {
        userContact = this.dialogData.user.email;
      } else if (channel === VerifyUserCheckParams.ChannelEnum.SMS) {
        userContact = ''; //user phone number if we start collecting them
      } else {
        userContact = this.dialogData.user.id;
      }
      this.verifyCheckForm.patchValue({
        user_contact: userContact,
      });
    });
  }

  verifyUserCheckForm(): UntypedFormGroup {
    return this._formBuilder.group({
      verification_code: [
        this.verifyUserCheckParams.verification_code,
        Validators.required,
      ],
      user_contact: [
        this.verifyUserCheckParams.user_contact,
        Validators.required,
      ],
      channel: [this.verifyUserCheckParams.channel],
    });
  }
}
