import { AdminDocument, DocumentStatusHistory } from '@admin-api/index';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DocumentStatusComponent implements OnChanges {
  @Input() status: AdminDocument.StatusEnum;
  @Input() statusHistory: DocumentStatusHistory[];
  @Input() rejectedReason: AdminDocument.RejectedReasonEnum;

  public displayStatus: string;
  public colorClass: 'red' | 'green' | 'blue' | 'yellow' = 'yellow';
  public tooltipText: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'status') {
        this.computeDisplayStatus();
      }
    }
  }

  private computeDisplayStatus(): void {
    // always reset default values
    // Capitalize the first letter
    this.displayStatus =
      this.status?.length > 1
        ? this.status.charAt(0).toUpperCase() + this.status.slice(1)
        : this.status;

    if (this.status === AdminDocument.StatusEnum.REJECTED) {
      this.displayStatus += ' - ';
      this.displayStatus +=
        this.rejectedReason?.length > 1
          ? (
              this.rejectedReason.charAt(0).toUpperCase() +
              this.rejectedReason.slice(1)
            ).replace('_', ' ')
          : this.rejectedReason;
    }

    this.colorClass = 'yellow';

    this.tooltipText = '';
    for (const history of this.statusHistory) {
      const dispStatus =
        history.status?.length > 1
          ? history.status.charAt(0).toUpperCase() + history.status.slice(1)
          : history.status;
      this.tooltipText += dispStatus + '\n';

      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTimezoneAbbr = new Date()
        .toLocaleTimeString('en-us', {
          timeZone: localTimezone,
          timeZoneName: 'short',
        })
        .split(' ')[2];

      const d = new Date(history.status_date);

      var options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: true,
        minute: '2-digit',
        hour: 'numeric',
        second: '2-digit',
      };

      const localText = d.toLocaleString('en-US', {
        timeZone: localTimezone,
        ...options,
      });
      const utcText = d.toLocaleString('en-US', {
        timeZone: 'UTC',
        ...options,
      });
      this.tooltipText += `${localTimezoneAbbr} • ${localText}\nUTC • ${utcText}\n\n`;
    }

    if (this.status === AdminDocument.StatusEnum.REQUESTED) {
      this.colorClass = 'yellow';
    } else if (this.status === AdminDocument.StatusEnum.SUBMITTED) {
      this.colorClass = 'blue';
    } else if (this.status === AdminDocument.StatusEnum.VERIFIED) {
      this.colorClass = 'green';
    } else if (this.status === AdminDocument.StatusEnum.REJECTED) {
      this.colorClass = 'red';
    }
  }
}
