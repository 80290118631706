/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PricingTemplate } from './pricingTemplate';


export interface ConnectedTemplate { 
    /**
     * The ID of the merchant account connected to this pricing template.
     */
    connected_account_id: string;
    /**
     * The status of this pricing template
     */
    connected_status: ConnectedTemplate.ConnectedStatusEnum;
    /**
     * Time at which the current status was entered.
     */
    connected_status_date: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The connected template details
     */
    pricing_template: PricingTemplate;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace ConnectedTemplate {
    export type ConnectedStatusEnum = 'active' | 'pending' | 'cancelled';
    export const ConnectedStatusEnum = {
        ACTIVE: 'active' as ConnectedStatusEnum,
        PENDING: 'pending' as ConnectedStatusEnum,
        CANCELLED: 'cancelled' as ConnectedStatusEnum
    };
}


