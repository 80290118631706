import { Route } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';

export const HOMEPAGE_ROUTE = 'accounts';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to HOMEPAGE_ROUTE
  { path: '', pathMatch: 'full', redirectTo: HOMEPAGE_ROUTE },

  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },

  // Redirect signed in user to the HOMEPAGE_ROUTE
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: HOMEPAGE_ROUTE,
  },

  // // Auth routes for guests
  // {
  //   path: '',
  //   canActivate: [NoAuthGuard],
  //   canActivateChild: [NoAuthGuard],
  //   component: LayoutComponent,
  //   data: {
  //     layout: 'empty',
  //   },
  //   children: [
  //     {
  //       path: 'sign-in',
  //       loadChildren: () =>
  //         import('app/modules/auth/sign-in/sign-in.module').then(
  //           (m) => m.AuthSignInModule,
  //         ),
  //     },
  //   ],
  // },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule,
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'accounts',
        loadChildren: () =>
          import('app/modules/accounts/accounts.module').then(
            (m) => m.AccountsModule,
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('app/modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'feature-toggles',
        loadChildren: () =>
          import('app/modules/feature-toggles/feature-toggles.module').then(
            (m) => m.FeatureTogglesModule,
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'api-dashboard',
        loadChildren: () =>
          import('app/modules/api-dashboard/api-dashboard.module').then(
            (m) => m.ApiDashboardModule,
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('app/modules/settings/settings.module').then(
            (m) => m.SettingsModule,
          ),
      },
      {
        path: 'dev',
        loadChildren: () =>
          import('app/modules/dev/dev.module').then((m) => m.DevModule),
      },
    ],
  },
  {
    path: '**',
    // If they aren't authenticated, homepage will redirect to auth login
    redirectTo: HOMEPAGE_ROUTE,
  },
];
