import { InternalTerminalReader } from '@admin-api/index';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'terminal-status',
  templateUrl: './terminal-status.component.html',
  styleUrls: ['./terminal-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TerminalStatusComponent implements OnChanges {
  @Input() terminal: InternalTerminalReader;

  public displayStatus: string;
  public colorClass: 'gray' | 'red' | 'green' | 'yellow' = 'gray';
  public icon: 'done' | 'undo' | 'close' | 'info' = null;
  public tooltipText: string = `Status Logic in Beta`;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'terminal') {
        this.computeDisplayStatus();
      }
    }
  }

  private computeDisplayStatus(): void {
    // always reset default values
    this.displayStatus = undefined;
    this.colorClass = 'gray';
    this.icon = null;
    this.tooltipText = undefined;

    switch (this.terminal.status) {
      case InternalTerminalReader.StatusEnum.CREATED: {
        this.displayStatus = 'Created';
        this.colorClass = 'yellow';
        this.icon = 'undo';
        break;
      }
      case InternalTerminalReader.StatusEnum.ENABLED: {
        this.displayStatus = 'Enabled';
        this.colorClass = 'yellow';
        this.icon = 'info';
        break;
      }
      case InternalTerminalReader.StatusEnum.DISABLED: {
        this.displayStatus = 'Disabled';
        this.colorClass = 'red';
        this.icon = 'close';
        break;
      }
      case InternalTerminalReader.StatusEnum.ACTIVE: {
        this.displayStatus = 'Active';
        this.colorClass = 'green';
        this.icon = 'done';
        break;
      }
    }
  }
}
