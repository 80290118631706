import {
  AdminGetContentsOfFileRequestParams,
  AdminGetFileRequestParams,
  AdminService,
  ListFilesRequestParams,
  ModelFile,
} from '@admin-api/index';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from '../../../core/constants';
import { VerifyDocumentDialogComponent } from '../verify-document/verify-document-dialog.component';
import { ViewDocumentDialogComponent } from '../view-document-dialog/view-document-dialog.component';

class FileViewModel {
  id: string;
  requirement: string;
  submitted_on: string;
}

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class FileListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  @Input() accountId: string;
  @Input() refreshData$: Observable<boolean>;
  @Output() refreshTimeline = new EventEmitter<boolean>();

  public dataSource = new MatTableDataSource<FileViewModel>();
  public filesCount: number = 0;
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;
  public dialogRef: any;

  errorMessage: string = null;
  isLoading: boolean = true;

  public filesTableColumns: string[] = [
    'requirement',
    'submitted_on',
    'view_download',
  ];
  searchInputControl: UntypedFormControl = new UntypedFormControl();

  private viewDocumentDialogRef: MatDialogRef<ViewDocumentDialogComponent>;
  private verifyDocumentDialogRef: MatDialogRef<VerifyDocumentDialogComponent>;

  constructor(
    private _adminService: AdminService,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this._paginator;
    this.retrieveFilteredDocuments();
  }

  ngAfterViewInit(): void {
    this._paginator.page.subscribe(() => {
      this.retrieveFilteredDocuments();
    });
  }

  ngOnDestroy(): void {}

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  retrieveFilteredDocuments() {
    let offset = 0;
    let limit = DEFAULT_PAGE_SIZE;
    if (this._paginator) {
      offset = this._paginator.pageIndex * this._paginator.pageSize;
      limit = this._paginator.pageSize;
    }

    this.retrieveDocuments(offset, limit);
  }

  retrieveDocuments(offset = 0, limit = DEFAULT_PAGE_SIZE) {
    this.isLoading = true;
    const requestParams: ListFilesRequestParams = {
      accountId: this.accountId,
      purposes: [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION as any],
      offset: offset,
      limit: limit,
    };

    this._adminService.listFilesWithoutDocument(requestParams).subscribe(
      (res) => {
        this.dataSource.data = this.fileDataToViewModel(res.items);
        this.filesCount = res.total;
      },
      (error) => {
        this.isLoading = false;
        if (error?.error?.message) {
          this.errorMessage = 'Error: ' + error.error.message;
        } else if (error?.message) {
          this.errorMessage = 'Error: ' + error.message;
        } else {
          this.errorMessage = 'Unknown error';
        }
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  private fileDataToViewModel(files: ModelFile[]): FileViewModel[] {
    let viewModel: FileViewModel[] = [];

    for (const file of files) {
      viewModel.push({
        id: file.id,
        requirement: file.title.split(':')[0],
        submitted_on: file.created_at,
      });
    }

    return viewModel;
  }

  public async viewFile(fileId: string): Promise<void> {
    let fileParams: AdminGetFileRequestParams = {
      accountId: this.accountId,
      id: fileId,
    };

    this._adminService.adminGetFile(fileParams).subscribe({
      next: (file) => {
        if (file) {
          let modelFile = file as ModelFile;
          let contentParams: AdminGetContentsOfFileRequestParams = {
            accountId: this.accountId,
            fileId: file.id,
          };

          this._adminService.adminGetContentsOfFile(contentParams).subscribe({
            next: (res) => {
              this.viewDocumentDialogRef = this._matDialog.open(
                ViewDocumentDialogComponent,
                {
                  panelClass: 'view-doc-dialog',
                  data: {
                    file: file,
                    data: res,
                    filename: modelFile.filename,
                  },
                  height: '100%',
                  maxHeight: '100vh',
                },
              );

              this.viewDocumentDialogRef
                .afterClosed()
                .subscribe((res: any) => {});
            },
            error: (err) => {
              this._snackBar.open(
                'Could not view file: ' + err?.status + ' ' + err?.statusText,
                null,
                {
                  duration: 5000,
                },
              );
            },
          });
        }
      },
      error: (error) => {
        if (error?.error?.message) {
          this.errorMessage = 'Error: ' + error.error.message;
        } else if (error?.message) {
          this.errorMessage = 'Error: ' + error.message;
        } else {
          this.errorMessage = 'Unknown error';
        }
      },
      complete: () => {},
    });
  }

  public async downloadFile(fileId: string): Promise<void> {
    let fileParams: AdminGetFileRequestParams = {
      accountId: this.accountId,
      id: fileId,
    };

    this._adminService.adminGetFile(fileParams).subscribe({
      next: (file) => {
        if (file) {
          let modelFile = file as ModelFile;
          let contentParams: AdminGetContentsOfFileRequestParams = {
            accountId: this.accountId,
            fileId: file.id,
          };

          let mimeType: string;
          switch (file.type) {
            case ModelFile.TypeEnum.PDF:
              mimeType = 'application/pdf';
              break;
            case ModelFile.TypeEnum.JPG:
              mimeType = 'image/jpg';
              break;
            case ModelFile.TypeEnum.PNG:
              mimeType = 'image/png';
              break;
            case ModelFile.TypeEnum.TIFF:
              mimeType = 'image/tiff';
              break;
            default:
              return;
          }

          this._adminService.adminGetContentsOfFile(contentParams).subscribe({
            next: (res) => {
              const blob = new Blob([res], { type: mimeType });
              const objectUrl = window.URL.createObjectURL(blob);
              const a = document.createElement('a') as HTMLAnchorElement;
              a.href = objectUrl;
              a.download = modelFile.filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(objectUrl);
            },
            error: (err) => {
              this._snackBar.open(
                'Could not download file: ' +
                  err?.status +
                  ' ' +
                  err?.statusText,
                null,
                {
                  duration: 5000,
                },
              );
            },
          });
        }
      },
      error: (error) => {
        if (error?.error?.message) {
          this.errorMessage = 'Error: ' + error.error.message;
        } else if (error?.message) {
          this.errorMessage = 'Error: ' + error.message;
        } else {
          this.errorMessage = 'Unknown error';
        }
      },
      complete: () => {},
    });
  }

  openSnackBar(message: string, action: string, warn: boolean): void {
    const snackTheme = warn ? 'mat-warn' : 'mat-primary';
    this._snackBar.open(message, action, {
      duration: 10000,
      verticalPosition: 'top',
      panelClass: ['mat-toolbar', snackTheme],
    });
  }
}
