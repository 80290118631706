<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Reject Document</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-8 max-w-128">
  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    This will reject the merchant's document, which will close the document. A
    new document will have to be requested.
  </div>

  <form [formGroup]="rejectDocForm">
    <div class="flex flex-col w-full">
      <div class="text-lg tracking-tight text-tilled-primary pt-3 pb-1">
        Rejection Reason
      </div>
      <mat-form-field class="flex-auto">
        <mat-select formControlName="rejectionReason" required>
          <mat-option *ngFor="let reason of rejectionReasons" [value]="reason"
            >{{ mapRejectionReasonToString(reason) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div
    class="text-lg font-bold tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    Are you sure you want to continue?
  </div>

  <div class="flex w-full justify-end">
    <button class="mr-4" mat-button (click)="cancelClicked()">Cancel</button>
    <button mat-flat-button color="primary" (click)="submitClicked()">
      <span>Reject Document</span>
    </button>
  </div>
</div>
