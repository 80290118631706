import { AdminService } from '@admin-api/index';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
})
export class GlobalSearchComponent implements OnInit {
  filterByIdInputControl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('filterByIdInput')
  filterByIdInput: ElementRef;
  filterByIdPendingChanges = false;
  public idNotFound: boolean = false;
  public internalError: boolean = false;
  public internalErrorString: string;

  constructor(private _router: Router, private _adminService: AdminService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.filterByIdInputControl.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((q) => {
        if (!q) {
          this.idNotFound = false;
          this.internalError = false;
        } else {
          this.filterByIdPendingChanges = true;
        }
      });
  }

  filterByIdEnterPressed(): void {
    this.fetchUrl();
    setTimeout(() => {
      (this.filterByIdInput.nativeElement as HTMLElement).blur();
    }, 5);
  }

  clearFilterById(): void {
    this.filterByIdInputControl.setValue('');
    setTimeout(() => {
      (this.filterByIdInput.nativeElement as HTMLElement).blur();
    }, 5);
  }

  fetchUrl(): void {
    let id: string;
    if (
      this.filterByIdInputControl.value &&
      typeof this.filterByIdInputControl.value === 'string'
    ) {
      id = this.filterByIdInputControl.value;
    }

    this._adminService.getURLById({ id }).subscribe(
      (response) => {
        if (response.account_id == null) {
          this.idNotFound = true;
          return;
        }

        if (response.sub_directory === 'accounts') {
          this._router.navigate([`accounts/${response.account_id}`]);
        } else {
          // sc-8269: Ensure the components reload with a hard-refresh
          this._router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this._router.navigate(
                [`accounts/${response.account_id}/${response.sub_directory}`],
                {
                  queryParams: {
                    ids: response.entity_id,
                  },
                  replaceUrl: true,
                },
              );
            });
        }
        this.idNotFound = false;
        this.internalError = false;
        this.clearFilterById();
      },
      (error) => {
        if (error.status === 404) {
          this.idNotFound = true;
        } else {
          this.internalError = true;
          this.internalErrorString = 'Internal Error ' + error.status;
        }
      },
    );
  }
}
