/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isRoutingNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    let isValid = false;
    if (value.length == 9) {
      isValid = isUSRoutingNumberValid(value);
    } else if (value.length == 8) {
      isValid = isCARoutingNumberValid(value);
    } else {
      isValid = false;
    }

    return isValid ? null : { invalidRoutingNumber: true };
  };
}

// http://www.brainjar.com/js/validation/
// Note that even if a number passes this test, it does not necessarily mean that it is valid.
// The number may not, in fact, be assigned to any financial institution.
function isUSRoutingNumberValid(routingNumber: string) {
  let isValid = false;
  // Checksum algorithm
  // Then we multiply the first digit by 3, the second by 7, the third by 1, the fourth by 3, the fifth by 7, the sixth by 1, etc., and add them all up.
  let n = 0;
  for (let i = 0; i < routingNumber.length; i += 3) {
    n +=
      parseInt(routingNumber.charAt(i), 10) * 3 +
      parseInt(routingNumber.charAt(i + 1), 10) * 7 +
      parseInt(routingNumber.charAt(i + 2), 10);
  }

  // If the resulting sum is an even multiple of ten (but not zero),
  // the aba routing number is good.

  isValid = n != 0 && n % 10 == 0;

  return isValid;
}

function isCARoutingNumberValid(routingNumber: string) {
  let isValid = false;
  let institutionId = getInstitutionIdFromRoutingNumber(routingNumber);
  if (ValidCAInstituionIds.includes(institutionId)) {
    isValid = true;
  }
  return isValid;
}

function getInstitutionIdFromRoutingNumber(routingNumber: string): string {
  if (!routingNumber || routingNumber.length != 8) {
    throw new Error('Please provide a valid Canadian routing number');
  }
  return routingNumber.substring(5, 8);
}

export const ValidCAInstituionIds = [
  '001',
  '002',
  '003',
  '004',
  '006',
  '010',
  '016',
  '030',
  '039',
  '117',
  '127',
  '177',
  '219',
  '245',
  '260',
  '269',
  '270',
  '308',
  '309',
  '310',
  '315',
  '320',
  '338',
  '340',
  '352',
  '509',
  '540',
  '608',
  '614',
  '618',
  '623',
  '809',
  '815',
  '819',
  '828',
  '829',
  '837',
  '839',
  '842',
  '865',
  '879',
  '889',
  '899',
];
