<div class="flex flex-col max-w-90 min-w-90 max-h-screen -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
  >
    <div class="text-lg font-medium">
      {{
        data?.user_email
          ? 'Impersonate ' + data.user_email
          : 'Impersonate ' + data?.user_id
      }}
    </div>
    <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_outline:x'"
      ></mat-icon>
    </button>
  </div>

  <form
    class="flex flex-col flex-auto p-8 overflow-y-auto"
    [formGroup]="impersonationForm"
  >
    <fieldset class="flex flex-col flex-auto">
      <mat-form-field>
        <mat-label>Reason</mat-label>
        <input
          matInput
          formControlName="reason"
          placeholder="e.g. Customer called for support"
          autocomplete="off"
        />
        <mat-error *ngIf="impersonationForm.controls['reason'].invalid"
          >Required</mat-error
        >
      </mat-form-field>
    </fieldset>

    <mat-checkbox
      *ngIf="data?.white_label_domain"
      class="pb-3"
      formControlName="goto_whitelabel_domain"
      [matTooltip]="'Navigate to ' + data.white_label_domain"
      >Use White Label Domain</mat-checkbox
    >

    <div class="flex flex-row-reverse mt-6">
      <button
        class="ml-4"
        mat-flat-button
        [color]="'primary'"
        (click)="proceed()"
        [disabled]="impersonationForm.invalid"
      >
        Proceed
      </button>
    </div>
  </form>
</div>
