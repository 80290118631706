<span class="w-max text-red-500 text-base pr-2"
*ngIf="idNotFound">Id not Found</span>
<span class="w-max text-red-500 text-base pr-2"
*ngIf="internalError">{{internalErrorString}}</span>
<mat-form-field
      class="fuse-mat-dense fuse-mat-no-subscript min-w-56 pr-2"
      appearance="fill"
    >
      <input
        class="py-2.5"
        matInput
        [formControl]="filterByIdInputControl"
        (keydown.enter)="filterByIdEnterPressed()"
        #filterByIdInput
        placeholder="Search by ID"
      />
      <button
        *ngIf="filterByIdInputControl.value && filterByIdPendingChanges"
        matSuffix
        mat-icon-button
        aria-label="Apply"
        color="primary"
        (click)="filterByIdEnterPressed()"
      >
        <mat-icon>check</mat-icon>
      </button>

      <button
        *ngIf="filterByIdInputControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearFilterById()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>