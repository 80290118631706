/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminCardFeeScheduleInfo { 
    account_id: string;
    account_monthly_fee: number;
    account_monthly_minimum_fee: number;
    account_setup_fee: number;
    active_on: string;
    card_brand?: AdminCardFeeScheduleInfo.CardBrandEnum;
    card_presence?: AdminCardFeeScheduleInfo.CardPresenceEnum;
    card_update_fee: number;
    chargeback_fee: number;
    currency: AdminCardFeeScheduleInfo.CurrencyEnum;
    discount_rate: number;
    discount_type: AdminCardFeeScheduleInfo.DiscountTypeEnum;
    id: string;
    max_volume: number;
    min_volume: number;
    non_standard: boolean;
    provider_account_id?: string;
    transaction_fee: number;
}
export namespace AdminCardFeeScheduleInfo {
    export type CardBrandEnum = 'amex' | 'diners' | 'discover' | 'jcb' | 'maestro' | 'mastercard' | 'solo' | 'visa' | 'visa_debit' | 'visa_electron' | 'unknown';
    export const CardBrandEnum = {
        AMEX: 'amex' as CardBrandEnum,
        DINERS: 'diners' as CardBrandEnum,
        DISCOVER: 'discover' as CardBrandEnum,
        JCB: 'jcb' as CardBrandEnum,
        MAESTRO: 'maestro' as CardBrandEnum,
        MASTERCARD: 'mastercard' as CardBrandEnum,
        SOLO: 'solo' as CardBrandEnum,
        VISA: 'visa' as CardBrandEnum,
        VISA_DEBIT: 'visa_debit' as CardBrandEnum,
        VISA_ELECTRON: 'visa_electron' as CardBrandEnum,
        UNKNOWN: 'unknown' as CardBrandEnum
    };
    export type CardPresenceEnum = 'present' | 'not_present';
    export const CardPresenceEnum = {
        PRESENT: 'present' as CardPresenceEnum,
        NOT_PRESENT: 'not_present' as CardPresenceEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type DiscountTypeEnum = 'flat' | 'cost_plus';
    export const DiscountTypeEnum = {
        FLAT: 'flat' as DiscountTypeEnum,
        COST_PLUS: 'cost_plus' as DiscountTypeEnum
    };
}


