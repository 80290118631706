<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Verify Document</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-8 max-w-128">

  <div
    class="text-lg font-semibold tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    Are you sure you want to verify this document?
  </div>

  <div class="flex w-full justify-end">
    <button class="mr-4" mat-button (click)="cancelClicked()">Cancel</button>
    <button mat-flat-button color="primary" (click)="submitClicked()">
      <span>Verify Document</span>
    </button>
  </div>
</div>
