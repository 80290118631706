<div
  class="inset-0 flex flex-col min-w-0 overflow-hidden bg-card dark:bg-transparent"
>
  <!-- Header -->
  <div
    class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b"
  >
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- Title -->
    <div class="text-4xl font-extrabold tracking-tight">Users</div>

    <!-- Actions -->
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <!-- Search -->
      <mat-form-field
        class="ml-4 fuse-mat-dense fuse-mat-no-subscript min-w-50"
        matTooltip="Matches partial name, partial email, or exact account id"
      >
        <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input
          matInput
          [formControl]="searchInputControl"
          [autocomplete]="'off'"
          [placeholder]="'Search users'"
        />
      </mat-form-field>
    </div>
  </div>

  <!-- Main -->
  <div class="flex flex-auto">
    <!-- Users list -->
    <div class="flex-col sm:mb-18">
      <ng-container *ngIf="usersCount > 0; else noUsers">
        <!-- Table wrapper -->
        <div class="overflow-x-auto sm:overflow-y-auto" cdkScrollable>
          <!-- Table -->
          <table
            class="w-full min-w-320 table-fixed bg-transparent"
            [ngClass]="{ 'pointer-events-none': isLoading }"
            mat-table
            [multiTemplateDataRows]="false"
            [dataSource]="dataSource"
            [trackBy]="trackByFn"
          >
            <ng-container matColumnDef="type">
              <th
                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Type
              </th>
              <td class="pr-4" mat-cell *matCellDef="let user">
                <mat-icon
                  class="text-gray-600 icon-size-5"
                  *ngIf="user.account.type === 'partner'"
                  [svgIcon]="'mat_outline:apartment'"
                ></mat-icon>
                <mat-icon
                  class="text-gray-400 icon-size-5"
                  *ngIf="user.account.type === 'merchant'"
                  [svgIcon]="'mat_outline:storefront'"
                ></mat-icon>
              </td>
            </ng-container>

            <!-- Name -->
            <ng-container matColumnDef="name">
              <th
                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                User
              </th>
              <td class="pr-8 truncate" mat-cell *matCellDef="let user">
                <div class="font-bold">
                  {{ user.name || '{ Empty }' }}
                </div>
                <div class="text-gray-400">
                  {{ user.email }}
                </div>
              </td>
            </ng-container>

            <!-- Account Name -->
            <ng-container matColumnDef="account_name">
              <th
                class="w-60 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Acct Name
              </th>
              <td class="pr-4" mat-cell *matCellDef="let user">
                {{ user.account.name }}
              </td>
            </ng-container>

            <!-- Role -->
            <ng-container matColumnDef="role">
              <th
                class="w-56 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Role
              </th>
              <td class="pr-4" mat-cell *matCellDef="let user">
                {{ user.role | snakeCaseSplit | titlecase }}
              </td>
            </ng-container>

            <!-- Last Login At -->
            <ng-container matColumnDef="last_login_at">
              <th
                class="w-30 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Last Login
              </th>
              <td class="pr-4" mat-cell *matCellDef="let user">
                <span class="flex items-center">
                  <mat-icon
                    class="icon-size-5 mr-3"
                    [svgIcon]="'mat_outline:lock'"
                    *ngIf="user.is_locked_out"
                    matTooltip="User is locked out"
                  ></mat-icon>
                  <span>{{ user.last_login_at | timeAgo }}</span>
                </span>
              </td>
            </ng-container>

            <!-- Last Request At -->
            <ng-container matColumnDef="last_request_at">
              <th
                class="w-30 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Last Request
              </th>
              <td class="pr-4" mat-cell *matCellDef="let user">
                {{ user.last_request_at | timeAgo }}
              </td>
            </ng-container>

            <!-- Verified -->
            <ng-container matColumnDef="verified">
              <th
                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Verified
              </th>
              <td class="pr-4 verified" mat-cell *matCellDef="let user">
                <span
                  [class]="user.verification_status"
                  [matTooltip]="verifyStatusTooltip(user.verification_status)"
                >
                  {{ user.verification_status }}
                </span>
              </td>
            </ng-container>

            <!-- Details -->
            <ng-container matColumnDef="actions">
              <th
                class="w-24 pr-8 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              ></th>
              <td class="pr-8" mat-cell *matCellDef="let user">
                <button
                  class="ml-auto -mr-4"
                  mat-icon-button
                  [matMenuTriggerFor]="userActionsMenu"
                >
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:dots-vertical'"
                  ></mat-icon>
                </button>

                <mat-menu #userActionsMenu="matMenu">
                  <button mat-menu-item (click)="impersonate(user.id)">
                    <span class="flex items-center">
                      <mat-icon
                        class="icon-size-5 mr-3"
                        [svgIcon]="'mat_outline:support_agent'"
                      ></mat-icon>
                      <span>Impersonate</span>
                    </span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="unlock(user.id)"
                    *ngIf="user.is_locked_out"
                  >
                    <span class="flex items-center">
                      <mat-icon
                        class="icon-size-5 mr-3"
                        [svgIcon]="'mat_outline:lock_open'"
                      ></mat-icon>
                      <span>Unlock</span>
                    </span>
                  </button>
                  <button mat-menu-item (click)="verify(user)">
                    <span class="flex items-center">
                      <mat-icon
                        class="icon-size-5 mr-3"
                        [svgIcon]="'mat_outline:check'"
                      ></mat-icon>
                      <span>Verify</span>
                    </span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr
              class="shadow"
              mat-header-row
              *matHeaderRowDef="usersTableColumns; sticky: true"
            ></tr>
            <tr
              class="h-18 hover:bg-gray-100 dark:hover:bg-hover"
              mat-row
              *matRowDef="let user; columns: usersTableColumns"
            ></tr>
            <!-- <tr class="h-0" mat-row *matRowDef="let row; columns: ['userDetails']"></tr> -->
          </table>
        </div>
      </ng-container>

      <mat-paginator
        class="sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
        [ngClass]="{ 'pointer-events-none': isLoading }"
        [length]="usersCount"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        [hidden]="usersCount === 0"
      ></mat-paginator>

      <ng-template #noUsers>
        <div
          *ngIf="!isLoading"
          class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center"
        >
          There are no users
          <div class="text-red-500 text-base" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
        <div
          *ngIf="isLoading"
          class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center"
        >
          Loading...
        </div>
      </ng-template>
    </div>
  </div>
</div>
