import {
  AdminDocument,
  AdminService,
  SubmitOnboardingDocumentRequestParams,
} from '@admin-api/index';
import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IUploadDocumentDialogData {
  type: string;
  requirement: string;
  documentId: string;
  accountId: string;
}

@Component({
  selector: 'app-submit-document-dialog',
  templateUrl: './submit-document-dialog.component.html',
  styleUrls: ['./submit-document-dialog.component.scss'],
})
export class SubmitDocumentDialogComponent {
  submitDocumentUploadForm: UntypedFormGroup;
  dialogTitle: string = 'Submit Merchant Onboarding Document';
  submitOnboardingDocumentParams: SubmitOnboardingDocumentRequestParams;
  isLoading = false;
  uploadComplete = true;
  fileToUpload = null;
  type = null;
  requirement = null;

  constructor(
    private _adminService: AdminService,
    public matDialogRef: MatDialogRef<SubmitDocumentDialogComponent>,
    private _snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IUploadDocumentDialogData,
  ) {
    this.type = dialogData.type;
    this.requirement = dialogData.requirement;
    this.submitOnboardingDocumentParams = {
      file: null,
      documentId: dialogData.documentId,
      writtenResponse: null,
      accountId: dialogData.accountId,
    };

    this.submitDocumentUploadForm = this.SubmitDocumentForm();
  }

  SubmitDocumentForm(): UntypedFormGroup {
    return this._formBuilder.group({
      file: [
        this.submitOnboardingDocumentParams.file,
        this.type === AdminDocument.TypeEnum.FILE ? Validators.required : null,
      ],
      writtenResponse: [
        this.submitOnboardingDocumentParams.writtenResponse,
        this.type === AdminDocument.TypeEnum.WRITTEN
          ? Validators.required
          : null,
      ],
    });
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    this.submitDocumentUploadForm.setValue({
      ...this.submitDocumentUploadForm.value,
      file: this.fileToUpload,
    });
  }

  save() {
    this.isLoading = true;
    this.submitOnboardingDocumentParams.file =
      this.submitDocumentUploadForm.value.file;
    this.submitOnboardingDocumentParams.writtenResponse =
      this.submitDocumentUploadForm.value.writtenResponse;
    this._adminService
      .submitOnboardingDocument(this.submitOnboardingDocumentParams)
      .subscribe(
        (res) => {
          this._snackBar.open('Upload successful', null, {
            duration: 3000,
          });
          this.matDialogRef.close(true);
        },
        (error) => {
          this.isLoading = false;
          this._snackBar.open(
            `Unable to submit: ${error.error.message}`,
            null,
            {
              duration: 3000,
            },
          );
        },
      );
  }
}
