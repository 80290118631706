<!-- Navigation -->
<fuse-vertical-navigation class="bg-card dark:bg-gray-900 print:hidden" [appearance]="'thin'"
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="data.navigation.compact"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img class="w-8" src="assets/images/logo/logo.svg" alt="Logo image" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="
            relative
            flex flex-0
            items-center
            w-full
            h-16
            px-4
            md:px-6
            z-49
            shadow
            dark:shadow-none
            dark:border-b
            bg-card
            dark:bg-transparent
            print:hidden
        ">
        <!-- Navigation toggle button -->
        <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <language></language>
            <fuse-fullscreen></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->

            <messages [messages]="data.messages"></messages>
            <notifications [notifications]="data.notifications"></notifications>
            <user-menu></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="
            relative
            flex flex-0
            items-center
            justify-start
            w-full
            h-14
            px-4
            md:px-6
            z-49
            border-t
            bg-card
            dark:bg-transparent
            print:hidden
        ">
        <span class="font-medium text-secondary">Fuse &copy; {{ currentYear }}</span>
    </div>
</div>
