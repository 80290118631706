import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'date-tooltip',
  templateUrl: './date-tooltip.component.html',
  styleUrls: ['./date-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateTooltipComponent implements OnChanges {
  @Input() date: Date;
  @Input() includeSeconds = false;
  @Input() includeTime = true;
  @Input() preferredTimeZone?: string;

  public tooltipText: string;
  public displayText: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'date') {
        this.computeTooltipText();
      }
    }
  }

  private computeTooltipText(): void {
    if (!this.date) {
      this.tooltipText = '';
      this.displayText = '';
      return;
    }

    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTimezoneAbbr = new Date()
      .toLocaleTimeString('en-us', {
        timeZone: localTimezone,
        timeZoneName: 'short',
      })
      .split(' ')[2];

    const d = new Date(this.date); // this.date

    var options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
      minute: '2-digit',
      hour: 'numeric',
      second: '2-digit',
    };

    const localText = d.toLocaleString('en-US', {
      timeZone: localTimezone,
      ...options,
    });
    const utcText = d.toLocaleString('en-US', {
      timeZone: 'UTC',
      ...options,
    });
    this.tooltipText = `${localTimezoneAbbr} • ${localText}
    UTC • ${utcText}
    `;

    let tz = localTimezone;
    if (this.preferredTimeZone) {
      tz = this.preferredTimeZone;
    }

    if (!this.includeTime) {
      this.displayText = d.toLocaleString('en-US', {
        timeZone: tz,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } else if (!this.includeSeconds) {
      this.displayText = d.toLocaleString('en-US', {
        timeZone: tz,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: true,
        minute: '2-digit',
        hour: 'numeric',
      });
    } else {
      this.displayText = d.toLocaleString('en-US', {
        timeZone: tz,
        ...options,
      });
    }
  }
}
