/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminNote { 
    account_id: string;
    admin_user_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    note: string;
    source_id: string;
    source_type: AdminNote.SourceTypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace AdminNote {
    export type SourceTypeEnum = 'account';
    export const SourceTypeEnum = {
        ACCOUNT: 'account' as SourceTypeEnum
    };
}


