<div class="w-full">
  <ng-container *ngIf="filesCount > 0; else noFiles">
    <!-- Table wrapper -->
    <div class="w-full" cdkScrollable>
      <!-- Table -->
      <table
        class="w-full bg-transparent"
        [ngClass]="{ 'pointer-events-none': isLoading }"
        mat-table
        [multiTemplateDataRows]="false"
        [dataSource]="dataSource"
        [trackBy]="trackByFn"
      >
        <ng-container matColumnDef="requirement">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Requirement
          </th>
          <td class="pr-2" mat-cell *matCellDef="let file">
            <span>{{ file.requirement | snakeCaseSplit | titlecase }}
            </span>
          </td>
        </ng-container>

        <!-- Submitted On -->
        <ng-container matColumnDef="submitted_on">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Submitted On
          </th>
          <td class="pr-2" mat-cell *matCellDef="let file">
            <date-tooltip
              [includeTime]="false"
              [date]="file.submitted_on"
            ></date-tooltip>
          </td>
        </ng-container>


        <!-- View/Download -->
        <ng-container matColumnDef="view_download">
          <th
            class="w-fit bg-gray-50 dark:bg-black dark:bg-opacity-5"
            mat-header-cell
            *matHeaderCellDef
          >
            Actions
          </th>
          <td class="pr-2 verified" mat-cell *matCellDef="let file">
            <button
              [permission]="'underwriting:write'"
              (click)="viewFile(file.id)"
            >
              View
            </button>
            <button
              class="pl-2"
              [permission]="'underwriting:write'"
              (click)="downloadFile(file.id)"
            >
              Download
            </button>
          </td>
        </ng-container>
        <tr
          class="shadow"
          mat-header-row
          *matHeaderRowDef="filesTableColumns; sticky: true"
        ></tr>
        <tr
          class="h-18 hover:bg-gray-100 dark:hover:bg-hover"
          mat-row
          *matRowDef="let file; columns: filesTableColumns"
        ></tr>
      </table>
    </div>
  </ng-container>

  <span *ngIf="filesCount <= 25">
  <mat-paginator
    class="sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    [ngClass]="{ 'pointer-events-none': isLoading }"
    [length]="filesCount"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
  ></mat-paginator>
  </span>

  <ng-template #noFiles>
    <div
      *ngIf="!isLoading"
      class="w-full p-8 text-2xl font-semibold tracking-tight text-center"
    >
      No information has been submitted yet
      <div class="text-red-500 text-base" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <div
      *ngIf="isLoading"
      class="w-full p-8 text-2xl font-semibold tracking-tight text-center"
    >
      Loading...
    </div>
  </ng-template>
</div>
