<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="text-center title dialog-title">Internal {{ sourceType }} Notes</span>
    <span class="spacer"></span>
    <button
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="flex flex-col flex-auto sm:mb-18 overflow-hidden">
    <div MatDialogActions class="flex justify-center pt-4 pb-4">
      <button
        mat-raised-button
        color="primary"
        class="pr-8 pl-8 edit-button"
        (click)="addNote()"
        aria-label="ADD"
      > <mat-icon
      class="mr-4 icon-size-5"
      [svgIcon]="'mat_outline:add'"
    ></mat-icon> Add Note
      </button>
    </div>
  <ng-container *ngIf="notesCount > 0; else noNotes">
    <div class="overflow-x-auto sm:overflow-y-auto" cdkScrollable>
    <!-- Table -->
    <table
      class="w-full bg-transparent table-fixed"
      [ngClass]="{ 'pointer-events-none': isLoading }"
      mat-table
      [multiTemplateDataRows]="false"
      [dataSource]="dataSource"
      [trackBy]="trackByFn"
    >
    <ng-container matColumnDef="note">
      <td class="pr-2" mat-cell *matCellDef="let note">
      <div fxLayout="row" fxLayoutAlign="start start">
        <div class="text-lg tracking-tight text-tilled-primary wrap-word">
         {{ note.note }}
        </div>
        <div class="text-sm tracking-tight text-tilled-secondary">
          Submitted: {{ note.submitted_on }} - By {{ note.admin_user }}
        </div>
      </div>
    </td>
    </ng-container>
    <tr
      class="h-18 border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-hover"
      mat-row
      *matRowDef="let note; let even = even; columns: notesTableColumns;"
      [ngClass]="{'bg-gray-200 hover:bg-hover': even}"
    ></tr>
</table>
</div>
</ng-container>
<mat-paginator
class="sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
[ngClass]="{ 'pointer-events-none': isLoading }"
[length]="notesCount"
[pageSizeOptions]="pageSizeOptions"
[showFirstLastButtons]="true"
[hidden]="notesCount === 0"
></mat-paginator>
</div>
<ng-template #noNotes>
  <div *ngIf="!isLoading" class="text-lg tracking-tight text-tilled-primary break-normal pt-8 pb-8 text-center">
    No notes currently for the {{sourceType}}.
  </div>
  <div
  *ngIf="isLoading"
  class="w-full p-8 text-2xl font-semibold tracking-tight text-center"
  >
  Loading...
</div>
</ng-template>

