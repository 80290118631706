/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CardChargeFeeTemplate } from './cardChargeFeeTemplate';
import { CardPresentChargeFeeTemplate } from './cardPresentChargeFeeTemplate';
import { DebitFeeTemplate } from './debitFeeTemplate';


export interface PricingTemplate { 
    /**
     * The ID of the Account associated with this pricing template.
     */
    account_id: string;
    /**
     * The account monthly fee configured for this pricing template, in currency minor units.
     */
    account_monthly_fee: number;
    /**
     * The account monthly minimum fee configured for this pricing template, in currency minor units.
     */
    account_monthly_minimum_fee: number;
    /**
     * If this is an `ach_debit` pricing template, this hash contains the direct debit specific pricing details.
     */
    ach_debit?: DebitFeeTemplate;
    /**
     * If this is a `card` pricing template, this hash contains the card specific pricing details.
     */
    card?: CardChargeFeeTemplate;
    /**
     * If this is a `card_present` pricing template, this hash contains the card specific pricing details.
     */
    card_present?: CardPresentChargeFeeTemplate;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The currency this pricing template applies to.
     */
    currency: PricingTemplate.CurrencyEnum;
    /**
     * If this is an `eft_debit` pricing template, this hash contains the direct debit specific pricing details.
     */
    eft_debit?: DebitFeeTemplate;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The name of this pricing template.
     */
    name: string;
    /**
     * The payment method type this pricing template applies to.
     */
    payment_method_type: PricingTemplate.PaymentMethodTypeEnum;
    /**
     * The status of this pricing template
     */
    status: PricingTemplate.StatusEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace PricingTemplate {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type PaymentMethodTypeEnum = 'card' | 'ach_debit' | 'card_present' | 'eft_debit';
    export const PaymentMethodTypeEnum = {
        CARD: 'card' as PaymentMethodTypeEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypeEnum,
        CARD_PRESENT: 'card_present' as PaymentMethodTypeEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypeEnum
    };
    export type StatusEnum = 'active' | 'archived' | 'pending';
    export const StatusEnum = {
        ACTIVE: 'active' as StatusEnum,
        ARCHIVED: 'archived' as StatusEnum,
        PENDING: 'pending' as StatusEnum
    };
}


