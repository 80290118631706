<div
  id="status-chip"
  [class]="colorClass"
  [matTooltip]="tooltipText"
  matTooltipPosition="above"
>
  {{ displayStatus }}
  <mat-icon id="status-icon" [class]="colorClass" *ngIf="icon">{{
    icon
  }}</mat-icon>
</div>
